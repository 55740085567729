import React from 'react';
import styled from 'styled-components';
import Menu from '../components/Menu/menu';
import Footer from '../components/Footer/footer';
import Layout from "../components/Layout/layout"
import image from '../assets/images/background.jpg'

const AboutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-image: ${({ image }) => `url(${image})`};
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 100%;
    height: 100vh;
`;

const MenuWrapper = styled.div`
    display:flex;
    flex-direction: column;
    height: 10vh;
`

const ContentWrapper = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    height: 80vh;
    width: 100%;
`

const Content = styled.div`
    width: 70%;
    height: 70vh;
    overflow:auto;
    padding: 30px;
    background-color: black;
    opacity:0.8;
    color: white;
`

const AboutPage = () => (
    <Layout>
        <AboutWrapper image={image}>
            <>
                <MenuWrapper>
                    <Menu showHome />
                </MenuWrapper>
                <ContentWrapper>
                    <Content>
                        text about
                    </Content>
                </ContentWrapper>
                <Footer />
            </>
        </AboutWrapper>
    </Layout>
)

export default AboutPage;